#IdContactPageAll {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
}

.contentAll {
    position: relative;
    z-index: 60;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.titleContact {
    margin-top: 30vh;
    font-family: titleone;
    font-size: 6vh;
    color: var(--red);

    color: #c3fffe;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 2.5vh var(--aqua),
            0 0 3vh var(--aqua),
            0 0 4vh var(--aqua);

    border-radius: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2.2vh;
    padding-bottom: 1vh;
    border: 0.4vh solid #ffc6c6;
    box-shadow:
        0 0 1vh var(--red),
        0 0 2vh var(--red),
        0 0 3vh var(--red),
        inset 0 0 1vh var(--red),
        inset 0 0 2vh var(--red);
}

.textContact {
    max-width: 40%;
    margin-top: 10vh;
    font-family: name;
    color: var(--white);
    font-size: 2.5vh;
    line-height: 1.7;
    text-indent: 2vh;
    text-align: justify;
}

.textContact a {
    text-decoration: none;
    color: var(--red);
}

.sociaMediaContact {
    margin-top: 3vh;
    margin-bottom: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

.sociaMediaContact button {
    position: relative;
    width: 5vh;
    height: 5vh;
    background-color: var(--white);
    cursor: pointer;display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
    box-shadow: 0 0 2vh var(--white);
}

.sociaMediaContact button:hover {
    background-color: var(--aqua);
    box-shadow: 0 0 5vh var(--aqua);
    box-shadow: 0 0 2vh var(--aqua);
    box-shadow: 0 0 1vh var(--aqua);
}

.sociaMediaContact img {
    position: absolute;
    width: 70%;
    height: 70%;
}

.socialMediaTxt {
    margin-top: 11vh;
    font-family: titleone;
    text-align: center;
    font-size: 4vh;
    color: #ffabab;
    text-shadow:
        0 0 0.5vh var(--red),
        0 0 1vh var(--red),
        0 0 1.5vh var(--red),
        0 0 2vh var(--red),
        0 0 2.5vh var(--red),
        0 0 3vh var(--red),
        0 0 4vh var(--red);
}

.findMeContact {
    font-family: titleone;
        font-size: 2.2vh;
        margin-top: 1vh;
        color: var(--aqua);
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 3vh var(--aqua),
            0 0 4vh var(--aqua);
}

.stripesContact {
    position: absolute;
    z-index: 50;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(
        90deg, 
        var(--purple) 25%, 
        transparent 25%, 
        transparent 50%, 
        var(--purple) 50%, 
        var(--purple) 75%, 
        transparent 75%, 
        transparent 100%
    );
    background-size: 50px 100%;
    opacity: 40%;
}

@media (max-width: 899px) {

    .titleContact {
        margin-top: 25vh;
        font-size: 3.6vh;
    }

    .textContact {
        max-width: 85%;
        font-size: 2vh;
    }

    .sociaMediaContact {
        gap: 2vh;
    }

    .sociaMediaContact button {
        width: 4vh;
        height: 4vh;
    }

    .socialMediaTxt {
        font-size: 3.3vh;
    }
    
    .findMeContact {
        font-size: 1.8vh;
    }

}