#allContentWorkId {
    position: relative;
    width: 100%;
    background-color: var(--aqua);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    overflow-x: hidden;


    background-image: radial-gradient(circle, #5ff4ff 0.4vh, transparent 20%);
    background-size: 3vh 3vh;
    background-repeat: repeat;
    height: 100vh;
    margin: 0;
}

.titleWorks {
    z-index: 2;
    margin-top: 30vh;
    font-family: titleone;
    font-size: 6vh;
    color: var(--red);

    color: #ffd3d3;
    text-shadow:
        0 0 0.5vh var(--red),
        0 0 1vh var(--red),
        0 0 1.5vh var(--red),
        0 0 2vh var(--red),
        0 0 2.5vh var(--red),
        0 0 3vh var(--red),
        0 0 4vh var(--red);

    border-radius: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2.2vh;
    padding-bottom: 1vh;
    border: 0.4vh solid #ffffff;
    box-shadow:
        0 0 1vh var(--white),
        0 0 2vh var(--white),
        0 0 3vh var(--white),
        inset 0 0 1vh var(--white),
        inset 0 0 2vh var(--white);
}

.textWorks {
    z-index: 2;
    max-width: 40%;
    margin-top: 10vh;
    font-family: name;
    color: var(--purpleBlack);
    font-size: 2.5vh;
    line-height: 1.7;
    text-align: center;
}

.worksFrameAll {
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10vh;
}

.workUnitFrame {
    z-index: 10;
    width: 40vh;
    height: 40vh;
    border-radius: 2.2vh;
    border: 0.5vh solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    cursor: pointer;
}

.workUnitFrame:hover {
    border: 0.4vh solid #ffffff;
    box-shadow:
        0 0 1vh var(--white),
        0 0 2vh var(--white),
        0 0 3vh var(--white),
        inset 0 0 1vh var(--white),
        inset 0 0 2vh var(--white);
}

.workUnitFrame:hover p:nth-child(3) {
    background-color: var(--red);
}

.workUnitFrame p:nth-child(1) {
    width: 70%;
    text-align: center;
    font-family: titleone;
    font-family: name;
    font-size: 3.5vh;
    color: transparent;
    -webkit-text-stroke: 0.2vh var(--purple);
}

.workUnitFrame p:nth-child(2) {
    width: 90%;
    text-align: center;
    font-family: titleone;
    font-family: name;
    font-size: 3vh;
    color: var(--purpleBlack);
}

.workUnitFrame p:nth-child(3) {
    width: 40%;
    height: 5vh;
    text-align: center;
    font-family: titleone;
    font-family: name;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
    font-size: 3vh;
    color: var(--aqua);
    background-color: var(--purple);
}

/* mobile */
@media (max-width: 899px) {

    .titleWorks {
        z-index: 2;
        margin-top: 25vh;
        font-size: 3.6vh;
    }

    .textWorks {
        z-index: 2;
        max-width: 85%;
        font-size: 2vh;
    }

    .workUnitFrame {
        width: 90%;
    }

    .splashblackWork {
        top: -22vh;
        right: -15vh;
        height: 70vh;
    }

}