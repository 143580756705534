#IDsectionAboutMe {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: var(--red);
    
    background-image: 
        linear-gradient(135deg, #ff3853 25%, transparent 25%),
        linear-gradient(225deg, #ff3853 25%, transparent 25%),
        linear-gradient(135deg, transparent 75%, #f1213d 75%),
        linear-gradient(225deg, transparent 75%, #f1213d 75%);
                
    background-size: 50px 50px;
    background-position: center;
    overflow: hidden;
}

.curtainAbout {
    z-index: 20;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--red);
    opacity: 60%;
}

#IdallContentAboutMe {
    z-index: 25;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
}

.titleAboutMe {
    position: relative;
    margin-top: 30vh;
    font-family: titleone;
    font-size: 6vh;
    color: var(--red);

    color: #c3fffe;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 2.5vh var(--aqua),
            0 0 3vh var(--aqua),
            0 0 4vh var(--aqua);

    border-radius: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2.2vh;
    padding-bottom: 1vh;
    border: 0.4vh solid #ffffff;
    box-shadow:
        0 0 1vh var(--white),
        0 0 2vh var(--white),
        0 0 3vh var(--white),
        inset 0 0 1vh var(--white),
        inset 0 0 2vh var(--white);
}

#IDsectionAboutMe div p {
    z-index: 30;
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
    max-width: 60%;
    font-family: name;
    font-size: 2.5vh;
    line-height: 1.7;
    color: var(--black);
    text-indent: 4vh;
    text-align: justify;
}

@media (max-width: 899px) {

    .titleAboutMe {
        margin-top: 25vh;
        font-size: 3.6vh;
    }

    #IDsectionAboutMe div p {
        max-width: 75%;
        font-size: 2vh;
    }

    .splashWhite {
        z-index: 22;
        position: absolute;
        height: 40vh;
        top: -10vh;
        right: -10vh;
    }
    
    .splashAqua {
        z-index: 22;
        position: absolute;
        height: 40vh;
        bottom: -10vh;
        left: -5vh;
    }
}