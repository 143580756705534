/* desktop */
@media (min-width: 900px) {
 
    .allmenuframe {
        position: fixed;
        top: 0vh;
        right: 16.8vh;
        z-index: 95;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .menuHambFrame {
        z-index: 95;
        position: absolute;
        top: 0vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .anchorAll {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3vh;
    }

    .anchorAll div {
        width: 0.4vh;
        height: 7vh;
        background-color: var(--gray1);
    }

    .anchorMenuAll {
        position: relative;
        width: 11vh;
        height: 11vh;
        background-color: var(--gray1);
        border-radius: 1vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5vh solid #494949;
    }

    .arrows {
        z-index: -1;
        border: 0.5vh solid #494949;
        position: absolute;
        left: -14vh;
        width: 16vh;
        height: 6vh;
        background-color: var(--gray1);
        border-radius: 1vh;
    }

    .anchorMenuNeom {
        position: absolute;
        width: 80%;
        height: 80%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--white);
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5vh;
    }

    .anchorMenuNeom div {
        position: relative;
        width: 70%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--aqua);
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .anchorMenuNeonOff {
        position: absolute;
        width: 80%;
        height: 80%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--white);
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    }

    .anchorMenuNeonOff div {
        position: absolute;
        width: 70%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--red);
        box-shadow:
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red),
            inset 0 0 2vh var(--red);
    }

    .anchorMenuNeonOff div:nth-child(1) {
        rotate: 45deg;
    }

    .anchorMenuNeonOff div:nth-child(2) {
        rotate: -45deg;
    }

    .menuText {
        position: absolute;
        top: 3.4vh;
        left: -12vh;
        font-size: 4vh;
        font-family: titleone;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red),
            0 0 5vh var(--red);
        opacity: 100%;
        animation: MenuLight 3s infinite;
    }

    @keyframes MenuLight {
        0% {opacity: 100%;}
        50% {opacity: 100%;}
        52% {opacity: 0%;}
        54% {opacity: 100%;}
        56% {opacity: 0%;}
        58% {opacity: 100%;}
        60% {opacity: 0%;}
        62% {opacity: 100%;}
        64% {opacity: 0%;}
        66% {opacity: 100%;}
        100% {opacity: 100%;}
    }

    .contentCourtain {
        z-index: 94;
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
    }

    .optionsFrame {
        position: relative;
        width: 100%;
        margin-top: 25vh;
        height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1vh;
    }

    .options {
        position: relative;
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .options p {
        font-family: titleone;
        font-size: 5vh;
        color: #b3fffb;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 2.5vh var(--aqua),
            0 0 3vh var(--aqua);
    }

    .options:hover p {
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red);
        animation: flashHoverMenu 5s infinite;
    }

    @keyframes flashHoverMenu {
        0% {opacity: 0%;}
        2% {opacity: 100%;}
        4% {opacity: 0%;}
        6% {opacity: 100%;}
    }

    .bottomLine {
        z-index: 100;
        position: absolute;
        bottom: 0vh;
        width: 100%;
        height: 0.2vh;
        background-color: var(--white);
        box-shadow:
            0 0 0.5vh var(--white),
            0 0 1vh var(--white),
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            0 0 3vh var(--white);
    }

}

/* mobile */
@media (max-width: 899px) {

    .allmenuframe {
        position: fixed;
        top: 0vh;
        right: 8.5vh;
        z-index: 95;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .menuHambFrame {
        z-index: 95;
        position: absolute;
        top: 0vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .anchorAll {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3vh;
    }

    .anchorAll div {
        width: 0.4vh;
        height: 6vh;
        background-color: var(--gray1);
    }

    .anchorMenuAll {
        position: relative;
        width: 9vh;
        height: 9vh;
        background-color: var(--gray1);
        border-radius: 1vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4vh solid #494949;
    }

    .arrows {
        z-index: -1;
        border: 0.4vh solid #494949;
        position: absolute;
        left: -12vh;
        width: 13vh;
        height: 5vh;
        background-color: var(--gray1);
        border-radius: 1vh;
    }

    .anchorMenuNeom {
        position: absolute;
        width: 80%;
        height: 80%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--white);
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1vh;
    }

    .anchorMenuNeom div {
        position: relative;
        width: 70%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.3vh solid var(--aqua);
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .menuText {
        position: absolute;
        top: 2.8vh;
        left: -10.5vh;
        font-size: 3.5vh;
        font-family: titleone;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red),
            0 0 5vh var(--red);
        opacity: 100%;
        animation: MenuLight 3s infinite;
    }

    @keyframes MenuLight {
        0% {opacity: 100%;}
        50% {opacity: 100%;}
        52% {opacity: 0%;}
        54% {opacity: 100%;}
        56% {opacity: 0%;}
        58% {opacity: 100%;}
        60% {opacity: 0%;}
        62% {opacity: 100%;}
        64% {opacity: 0%;}
        66% {opacity: 100%;}
        100% {opacity: 100%;}
    }

    .anchorMenuNeonOff {
        position: absolute;
        width: 80%;
        height: 80%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.4vh solid var(--white);
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    }

    .anchorMenuNeonOff div {
        position: absolute;
        width: 70%;
        border-radius: 1vh;
        cursor: pointer;
        border: 0.3vh solid var(--red);
        box-shadow:
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red),
            inset 0 0 2vh var(--red);
    }

    .anchorMenuNeonOff div:nth-child(1) {
        rotate: 45deg;
    }

    .anchorMenuNeonOff div:nth-child(2) {
        rotate: -45deg;
    }

    .contentCourtain {
        z-index: 94;
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
    }

    .optionsFrame {
        position: relative;
        width: 100%;
        margin-top: 25vh;
        height: 63vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1vh;
    }

    .options {
        position: relative;
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .options p {
        font-family: titleone;
        font-size: 3.5vh;
        color: #b3fffb;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 2.5vh var(--aqua),
            0 0 3vh var(--aqua);
    }

    .options:hover p {
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red);
            animation: flashHoverMenu 5s infinite;
        }
    
        @keyframes flashHoverMenu {
            0% {opacity: 0%;}
            2% {opacity: 100%;}
            4% {opacity: 0%;}
            6% {opacity: 100%;}
        }

    .bottomLine {
        z-index: 100;
        position: absolute;
        bottom: 0vh;
        width: 100%;
        height: 0.2vh;
        background-color: var(--white);
        box-shadow:
            0 0 0.5vh var(--white),
            0 0 1vh var(--white),
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            0 0 3vh var(--white);
    }

}