/* desktop */
@media (min-width: 900px) {

    .allHomeFrame {
        z-index: 100;
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        gap: 4vh;
        padding: 0 4vh;
        box-sizing: border-box;

        background-color: var(--purple);
    
        background-image: 
            linear-gradient(135deg, #5413c5 25%, transparent 25%),
            linear-gradient(225deg, #5413c5 25%, transparent 25%),
            linear-gradient(135deg, transparent 75%, #3b0f87 75%),
            linear-gradient(225deg, transparent 75%, #3b0f87 75%);
                    
        background-size: 50px 50px;
        background-position: center;
        
    }

    .frameone {
        z-index: 2;
        position: relative;
        width: 100%;
        height: 90%;
        border-radius: 3vh;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4vh solid var(--white);
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
    }

    .frametwo {
        position: relative;
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 2vh;
    }

    .rightTopFrame {
        position: relative;
        width: 100%;
        min-width: 50vh;
        flex: 1;
        border-radius: 3vh;
        border: 0.4vh solid #d1fafd;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .rightBottomFrame {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 30vh;
        border-radius: 3vh;
        border: 0.4vh solid #ffb7c1;
        box-shadow:
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red),
            inset 0 0 2vh var(--red);
    }

    .homeSplashBlack1 {
        z-index: -1;
        object-fit: cover;
        position: absolute;
        height: 110vh;
        top: -30vh;
        left: -25vh;
    }

    .homeSplashAqua1 {
        z-index: -1;
        position: absolute;
        height: 60vh;
        top: 10vh;
        left: 0vh;
    }

    .homeAvatar {
        position: absolute;
        height: 65vh;
        bottom: 0vh;
        animation: avatarFlash 15s infinite;
    }

    @keyframes avatarFlash {
        0%, 20% {
            filter: brightness(1);
        }
        20.1% {
            filter: brightness(1);
        }
        20.2% {
            filter: brightness(7);
        }
        20.3% {
            filter: brightness(0);
        }
        20.4% {
            filter: brightness(15);
        }
        20.5% {
            filter: brightness(1);
        }
    }

    .seeYouSoonFrame {
        position: absolute;
        bottom: 4vh;
        padding-top: 2vh;
        padding-bottom: 1vh;
        padding-left: 4vh;
        padding-right: 4vh;
        border-radius: 2vh;
        border: 0.4vh solid #caf5ff;
        box-shadow:
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 3vh var(--aqua),
            inset 0 0 1vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .seeYouSoon {
        font-family: titleone;
        text-align: center;
        font-size: 6vh;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red);
        opacity: 100%;
        animation: seeYouSoonLight 2s infinite;
    }

    @keyframes seeYouSoonLight {
        0% {opacity: 100%;}
        50% {opacity: 100%;}
        52% {opacity: 0%;}
        54% {opacity: 100%;}
        56% {opacity: 0%;}
        58% {opacity: 100%;}
        100% {opacity: 100%;}
    }

    /* top one move init */ 
    .wrapper {
        position: absolute;
        top: 5vh;
        max-width: 100%;
        overflow: hidden;
    }
      
    .topOneBin {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: moveToLeft 30s linear infinite;
    }

    .topOneBin p {
        display: inline-block;
        font-size: 3vh;
        font-family: text;
        color: white;
    }
      
    @keyframes moveToLeft {
        0% {
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(-50%, 0, 0);
        }
    }

    /* top one move end */ 

    /* top two move init */
    .wrapperTwo {
        position: absolute;
        top: 10vh;
        max-width: 100%;
        overflow: hidden;
    }
      
    .topTwoBin {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: moveToRight 40s linear infinite;
    }
      
    .topTwoBin p {
        display: inline-block;
        font-size: 3.5vh;
        font-family: text;
        color: white;
    }
      
    @keyframes moveToRight {
        0% {
          transform: translate3d(-50%, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }
    /* top two move end */

    .homeSplashBlack2 {
        position: absolute;
        height: 90vh;
        top: -38vh;
        right: -25vh;
    }

    .homeSplashWhite1 {
        position: absolute;
        height: 45vh;
        top: -8vh;
        right: 0vh;
    }

    .textBottom {
        position: absolute;
        bottom: 3vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
    }

    .nameContainer {
        position: relative;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .namewhite {
        z-index: 10;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 7vh;
        -webkit-text-stroke: 0.23vh var(--white);
        font-size: 8.5vh;
        text-align: end;
        animation: 5s glowWhite ease-in-out infinite;
    }

    @keyframes glowWhite {
        0% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
        94% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
        95% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, var(--red)),
                -1vh -1.5vh 0vh var(--glow-color, var(--red));
        }
        100% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
    }

    .nameRed {
        z-index: 8;
        position: absolute;
        bottom: -0.4vh;
        right: 0vh;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 7vh;
        -webkit-text-stroke: 0.2vh var(--red);
        font-size: 8.5vh;
        text-align: end;
    }

    .nameAqua {
        z-index: 9;
        position: absolute;
        top: -0.5vh;
        right: 0vh;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 7vh;
        -webkit-text-stroke: 0.2vh var(--aqua);
        font-size: 8.5vh;
        text-align: end;
    }

    .textHi {
        font-size: 4vh;
        font-family: titleone;
        text-transform: capitalize;
        margin-right: 7vh;
        color: #90f8ff;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua);
    }

    .textEngineer {
        font-size: 4vh;
        font-family: titleone;
        text-transform: capitalize;
        margin-right: 7vh;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red),
            0 0 5vh var(--red);
    }

    .rightBottomFrame p:nth-child(1) {
        z-index: 9;
        position: absolute;
        top: 3vh;
        right: 7vh;
        font-family: titleone;
        font-size: 6vh;
        color: #ffabb6;
        animation: glowMedia 2s ease-in-out infinite;
    }

    @keyframes glowMedia {

        0%{
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red);
        }

        50% {
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red),
                0 0 2.5vh var(--red),
                0 0 3vh var(--red),
                0 0 3.5vh var(--red),
                0 0 4vh var(--red),
                0 0 4.5vh var(--red),
                0 0 5vh var(--red);
        }

        100%{
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red);
        }
        
    }

    .rightBottomFrame p:nth-child(2) {
        z-index: 9;
        position: absolute;
        top: 10vh;
        right: 7vh;
        font-family: titleone;
        font-size: 2.5vh;
        color: var(--aqua);
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 3vh var(--aqua),
            0 0 4vh var(--aqua);
    }

    .sociaMedia {
        z-index: 10;
        position: absolute;
        right: 7vh;
        bottom: 3vh;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3vh;
    }

    .sociaMedia button {
        position: relative;
        width: 5vh;
        height: 5vh;
        background-color: var(--white);
        cursor: pointer;display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1vh;
        box-shadow: 0 0 2vh var(--white);
    }

    .sociaMedia button:hover {
        background-color: var(--aqua);
        box-shadow: 0 0 5vh var(--aqua);
        box-shadow: 0 0 2vh var(--aqua);
        box-shadow: 0 0 1vh var(--aqua);
    }

    .socialBTN img {
        position: absolute;
        width: 70%;
        height: 70%;
    }

    .vacancyAll {
        position: absolute;
        top: 8vh;
        left: 0vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .anchorLeft {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .anchorLeft div {
        width: 1.2vh;
        height: 1vh;
        background-color: var(--gray1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.3vh solid #494949;
    }

    .signBkg {
        border-radius: 1vh;
        width: 4.5vh;
        height: 30vh;
        background-color: var(--gray1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4vh solid #494949;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.8vh;
    }

    .signBkg p {
        font-family: titleone;
        font-size: 2.3vh;
        color: #ffe6df;
        text-align: center;
        text-shadow:
            0 0 0.5vh var(--orange),
            0 0 1vh var(--orange),
            0 0 1.5vh var(--orange),
            0 0 2vh var(--orange),
            0 0 2.5vh var(--orange),
            0 0 3vh var(--orange),
            0 0 3.5vh var(--orange),
            0 0 4vh var(--orange),
            0 0 4.5vh var(--orange),
            0 0 5vh var(--orange);
    }
    
    .signBkg div {
        font-family: titleone;
        font-size: 2.3vh;
        color: #494949;
        text-align: center;
    }
    
}

/* mobile */
@media (max-width: 899px) {

    .allHomeFrame {
        z-index: 100;
        width: 100%;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: end;

        background-color: var(--purple);
    
        background-image: 
            linear-gradient(135deg, #5413c5 25%, transparent 25%),
            linear-gradient(225deg, #5413c5 25%, transparent 25%),
            linear-gradient(135deg, transparent 75%, #3b0f87 75%),
            linear-gradient(225deg, transparent 75%, #3b0f87 75%);
                    
        background-size: 50px 50px;
        background-position: center;
    }

    .frameone {
        z-index: 5;
        position: relative;
        width: 90%;
        margin-top: 4vh;
        margin-bottom: 4vh;
        height: 75vh;
        border-radius: 3vh;
        border: 0.4vh solid var(--white);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow:
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            inset 0 0 1.5vh var(--white),
            inset 0 0 2vh var(--white);
    }

    .frametwo {
        margin-top: 4vh;
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4vh;
    }

    .rightTopFrame {
        z-index: 9;
        position: relative;
        width: 100%;
        height: 70vh;
        border-radius: 3vh;
        border: 0.4vh solid #d1fafd;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);

    }

    .rightBottomFrame {
        z-index: 1;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 40vh;
        border-radius: 3vh;
        border: 0.4vh solid #ffb7c1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow:
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red),
            inset 0 0 2vh var(--red);
    }

    .homeSplashBlack1 {
        z-index: -1;
        position: absolute;
        height: 110vh;
        top: -30vh;
        left: -30vh;
    }

    .homeSplashAqua1 {
        z-index: -1;
        position: absolute;
        height: 60vh;
        top: 10vh;
        left: 0vh;
    }

    .homeAvatar {
        position: absolute;
        height: 65vh;
        bottom: 0vh;
        animation: avatarFlash 15s infinite;
    }

    @keyframes avatarFlash {
        0%, 20% {
            filter: brightness(1);
        }
        20.1% {
            filter: brightness(1);
        }
        20.2% {
            filter: brightness(7);
        }
        20.3% {
            filter: brightness(0);
        }
        20.4% {
            filter: brightness(15);
        }
        20.5% {
            filter: brightness(1);
        }
    }

    /* top one move init */ 
    .wrapper {
        position: absolute;
        top: 5vh;
        max-width: 100%;
        overflow: hidden;
    }
      
    .topOneBin {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: moveToLeft 30s linear infinite;
    }

    .topOneBin p {
        display: inline-block;
        font-size: 3vh;
        font-family: text;
        color: white;
    }
      
    @keyframes moveToLeft {
        0% {
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(-50%, 0, 0);
        }
    }

    /* top one move end */ 

    /* top two move init */
    .wrapperTwo {
        position: absolute;
        top: 9vh;
        max-width: 100%;
        overflow: hidden;
    }
      
    .topTwoBin {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        animation: moveToRight 40s linear infinite;
    }
      
    .topTwoBin p {
        display: inline-block;
        font-size: 3.5vh;
        font-family: text;
        color: white;
    }
      
    @keyframes moveToRight {
        0% {
          transform: translate3d(-50%, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }
    /* top two move end */

    .homeSplashBlack2 {
        z-index: -1;
        position: absolute;
        height: 90vh;
        top: -38vh;
        right: -27vh;
    }

    .homeSplashWhite1 {
        z-index: -2;
        position: absolute;
        height: 35vh;
        top: -5vh;
        right: -9vh;
    }

    .textBottom {
        position: absolute;
        bottom: 3vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        gap: 1vh;
    }

    .nameContainer {
        position: relative;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .namewhite {
        z-index: 10;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 2vh;
        -webkit-text-stroke: 0.23vh var(--white);
        font-size: 6.3vh;
        text-align: end;
        animation: 5s glowWhite ease-in-out infinite;
    }

    @keyframes glowWhite {
        0% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
        94% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
        95% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, var(--red)),
                -1vh -1.5vh 0vh var(--glow-color, var(--red));
        }
        100% {
            text-shadow:
                1vh 1.5vh 0vh var(--glow-color, transparent),
                -1vh -1.5vh 0vh var(--glow-color, transparent);
        }
    }

    .nameRed {
        z-index: 8;
        position: absolute;
        bottom: -0.4vh;
        right: 0vh;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 2vh;
        -webkit-text-stroke: 0.2vh var(--red);
        font-size: 6.3vh;
        text-align: end;
    }

    .nameAqua {
        z-index: 9;
        position: absolute;
        top: -0.4vh;
        right: 0vh;
        color: transparent;
        font-family: name;
        text-transform: capitalize;
        margin-right: 2vh;
        -webkit-text-stroke: 0.2vh var(--aqua);
        font-size: 6.3vh;
        text-align: end;
    }

    .textHi {
        font-size: 2.7vh;
        font-family: titleone;
        text-transform: capitalize;
        margin-right: 2vh;
        color: #90f8ff;
        text-shadow:
            0 0 0.5vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua);
    }

    .textEngineer {
        font-size: 2.7vh;
        font-family: titleone;
        text-transform: capitalize;
        margin-right: 2vh;
        color: #ff8696;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red),
            0 0 5vh var(--red);
    }

    .rightBottomFrame p:nth-child(1) {
        z-index: 9;
        position: absolute;
        top: 5vh;
        width: 100%;
        text-align: end;
        padding-right: 2vh;
        font-family: titleone;
        font-size: 3.5vh;
        color: #ffabb6;
        animation: glowMedia 2s ease-in-out infinite;
    }

    @keyframes glowMedia {

        0%{
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red);
        }

        50% {
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red),
                0 0 2.5vh var(--red),
                0 0 3vh var(--red),
                0 0 3.5vh var(--red),
                0 0 4vh var(--red),
                0 0 4.5vh var(--red),
                0 0 5vh var(--red);
        }

        100%{
            text-shadow:
                0 0 0.5vh var(--red),
                0 0 1vh var(--red),
                0 0 1.5vh var(--red),
                0 0 2vh var(--red);
        }
        
    }

    .rightBottomFrame p:nth-child(2) {
        z-index: 9;
        position: absolute;
        top: 10vh;
        width: 100%;
        text-align: end;
        padding-right: 2vh;
        font-family: titleone;
        font-size: 2.3vh;
        color: var(--aqua);
        text-shadow:
                0 0 0.5vh var(--aqua),
                0 0 1vh var(--aqua),
                0 0 1vh var(--aqua),
                0 0 2vh var(--aqua),
                0 0 3vh var(--aqua),
                0 0 4vh var(--aqua);
    }

    .sociaMedia {
        z-index: 10;
        position: absolute;
        width: 100%;
        bottom: 4vh;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2vh;
    }

    .sociaMedia button {
        position: relative;
        width: 4vh;
        height: 4vh;
        background-color: var(--white);
        cursor: pointer;display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1vh;
        box-shadow: 0 0 2vh var(--white);
    }

    .sociaMedia button:hover {
        background-color: var(--aqua);
        box-shadow: 0 0 5vh var(--aqua);
        box-shadow: 0 0 2vh var(--aqua);
        box-shadow: 0 0 1vh var(--aqua);
    }

    .socialBTN img {
        position: absolute;
        width: 70%;
        height: 70%;
    }

    .seeYouSoonFrame {
        position: absolute;
        bottom: 3vh;
        width: 80%;
        padding-top: 2vh;
        padding-bottom: 1vh;
        border-radius: 2vh;
        border: 0.3vh solid #caf5ff;;
        box-shadow:
            0 0 1vh var(--aqua),
            0 0 2vh var(--aqua),
            0 0 3vh var(--aqua),
            inset 0 0 1vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .seeYouSoon {
        font-family: titleone;
        text-align: center;
        font-size: 3.5vh;
        color: #ffabab;
        text-shadow:
            0 0 0.5vh var(--red),
            0 0 1vh var(--red),
            0 0 1.5vh var(--red),
            0 0 2vh var(--red),
            0 0 2.5vh var(--red),
            0 0 3vh var(--red),
            0 0 4vh var(--red);
        opacity: 100%;
        animation: seeYouSoonLight 2s infinite;
    }
    
    @keyframes seeYouSoonLight {
        0% {opacity: 100%;}
        50% {opacity: 100%;}
        52% {opacity: 0%;}
        54% {opacity: 100%;}
        56% {opacity: 0%;}
        58% {opacity: 100%;}
        100% {opacity: 100%;}
    }

    .vacancyAll {
        position: absolute;
        top: 8vh;
        left: 0vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .anchorLeft {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .anchorLeft div {
        width: 1.2vh;
        height: 1vh;
        background-color: var(--gray1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.3vh solid #494949;
    }

    .signBkg {
        border-radius: 1vh;
        width: 4.5vh;
        height: 30vh;
        background-color: var(--gray1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4vh solid #494949;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.8vh;
    }

    .signBkg p {
        font-family: titleone;
        font-size: 2.3vh;
        color: #ffe6df;
        text-align: center;
        text-shadow:
            0 0 0.5vh var(--orange),
            0 0 1vh var(--orange),
            0 0 1.5vh var(--orange),
            0 0 2vh var(--orange),
            0 0 2.5vh var(--orange),
            0 0 3vh var(--orange),
            0 0 3.5vh var(--orange),
            0 0 4vh var(--orange),
            0 0 4.5vh var(--orange),
            0 0 5vh var(--orange);
    }
    
    .signBkg div {
        font-family: titleone;
        font-size: 2.3vh;
        color: #494949;
        text-align: center;
    }

}