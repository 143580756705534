/* desktop */
@media (min-width: 800px) {

    .allcontentLoader {
        z-index: 100;
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .circleBlack {
        position: absolute;
        width: 30vh;
        height: 30vh;
        background-color: var(--black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4vh solid #fffbe6;
        box-shadow:
            0 0 0.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red);
    }

    .blackInsideCircle {
        position: absolute;
        width: 24vh;
        height: 24vh;
        background-color: var(--black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: rotateCircle 1.5s linear infinite;
    }

    @keyframes rotateCircle {
        0% {
            box-shadow: 0.5vh 0.1vh 0.1vh 0.4vh var(--red);
            transform: rotateZ(0deg)
        }
        25% {
            box-shadow: 0.5vh 0.1vh 0.1vh 0.4vh var(--purple);
        }
        50% {
            box-shadow: 0.5vh 0.1vh 0.1vh 0.4vh var(--aqua);
        }
        75% {
            box-shadow: 0.5vh 0.1vh 0.1vh 0.4vh var(--white);
        }
        100% {
            box-shadow: 0.5vh 0.1vh 0.1vh 0.4vh var(--red);
            transform: rotateZ(360deg)
        }
    }

    .squareFrame {
        z-index: 9;
        position: absolute;
        width: 92%;
        height: 92%;
        border-radius: 2vh;
        border: 0.4vh solid #f8bb75;
        border: 0.4vh solid #bbefff;
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

    .textLoading {
        position: absolute;
        font-family: titleone;
        font-size: 2.5vh;
        color: var(--red);
        -webkit-text-stroke: 0.1vh #ffffff;
        text-shadow:
            0 0 0.5vh var(--white),
            0 0 1vh var(--white),
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            0 0 2.5vh var(--white),
            0 0 3vh var(--white),
            0 0 3.5vh var(--white);
    }

    .loaderSplashBlack1 {
        position: absolute;
        height: 170vh;
        top: -88vh;
        left: -58vh;
    }

    .loaderSplashRed1 {
        position: absolute;
        height: 70vh;
        left: -47vh;
    }

    .loaderSplashAqua1 {
        position: absolute;
        height: 70vh;
        right: -34vh;
    }

}

/* mobile */
@media (max-width: 799px) {

    .allcontentLoader {
        z-index: 100;
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, var(--purpleBlack), var(--purple));
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .circleBlack {
        position: absolute;
        width: 17vh;
        height: 17vh;
        background-color: var(--black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.2vh solid #ffc6c6;
        box-shadow:
            0 0 0.5vh var(--red),
            0 0 2vh var(--red),
            inset 0 0 1.5vh var(--red);
    }

    .blackInsideCircle {
        position: absolute;
        width: 13vh;
        height: 13vh;
        background-color: var(--black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: rotateCircle 1.5s linear infinite;
    }

    @keyframes rotateCircle {
        0% {
            box-shadow: 0.3vh 0.1vh 0.1vh 0.1vh var(--red);
            transform: rotateZ(0deg)
        }
        25% {
            box-shadow: 0.3vh 0.1vh 0.1vh 0.1vh var(--purple);
        }
        50% {
            box-shadow: 0.3vh 0.1vh 0.1vh 0.1vh var(--aqua);
        }
        75% {
            box-shadow: 0.3vh 0.1vh 0.1vh 0.1vh var(--white);
        }
        100% {
            box-shadow: 0.3vh 0.1vh 0.1vh 0.1vh var(--red);
            transform: rotateZ(360deg)
        }
    }

    .textLoading {
        position: absolute;
        font-family: titleone;
        font-size: 1.5vh;
        color: var(--red);
        -webkit-text-stroke: 0.1vh #f8bb75;
        -webkit-text-stroke: 0.1vh #ffffff;
        text-shadow:
            0 0 0.5vh var(--white),
            0 0 1vh var(--white),
            0 0 1.5vh var(--white),
            0 0 2vh var(--white),
            0 0 2.5vh var(--white),
            0 0 3vh var(--white),
            0 0 3.5vh var(--white);
    }

    .loaderSplashBlack1 {
        position: absolute;
        height: 80vh;
        top: -38vh;
        left: -26vh;
    }

    .loaderSplashRed1 {
        position: absolute;
        height: 40vh;
        left: -23vh;
    }

    .loaderSplashAqua1 {
        position: absolute;
        height: 40vh;
        right: -20vh;
    }

    .squareFrame {
        z-index: 9;
        position: fixed;
        top: 4vh;
        width: 92%;
        height: 92%;
        border-radius: 2vh;
        border: 0.4vh solid #bbefff;
        box-shadow:
            0 0 1.5vh var(--aqua),
            0 0 2vh var(--aqua),
            inset 0 0 1.5vh var(--aqua),
            inset 0 0 2vh var(--aqua);
    }

}