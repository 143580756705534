@import './1_colors.css';

@font-face {
	font-family: 'titleone';
	src: url('./1_fonts/titleone.ttf') format('truetype');
}

@font-face {
	font-family: 'text';
	src: url('./1_fonts/text.ttf') format('truetype');
}

@font-face {
	font-family: 'name';
	src: url('./1_fonts/name.ttf') format('truetype');
}


* {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
}

/* desktop */
@media (min-width: 900px) {

    #mainFrameID {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #mainFrameID section {
        position: fixed;
        top: 0vh;
        width: 100%;
        height: 100vh;
    }

}

/* mobile */
@media (max-width: 899px) {

    #mainFrameID {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #mainFrameID section {
        width: 100%;
    }

}