:root {
    --purple: #4d14af;
    --purpleBlack: #090118;
    --red: #FE2643;
    --aqua: #00EEFF;
    --orange: #ff531f;
    --yellow: #ffe60a;

    --black: #000000;
    --white: #ffffff;

    --gray1: #1c1c1c;
    --gray2: #eeeeee;
}